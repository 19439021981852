@import '~@one/web-components/dist/owc/owc.css';
@import '~@one/icons/dist/one-icons-filled.css';
@import '~@one/icons/dist/one-icons-outlined.css';
@import '~@one/design-tokens/css/navify/light/variables.css';
@import '~@one/roche-font/roche-font.css';

.owc-table-row--expanded > .owc-table-row__expanded-wrapper {
    z-index: 101;
}

div.tabContents {
    padding-top: 0.5em;
    padding-bottom: 4em;
}

.App {
    text-align: left;
    margin: 5%;
}

select {
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #eee;
    border-bottom: 1px solid #111;
    height: 34px;
    background-color: #EEE;
}

body {
    overflow-x: hidden 
}

:not(owc-icon){
    font-family: "Roche Sans";
    font-size: 14px;
}
